import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  goTop() {
    if (isPlatformBrowser(this.platformId)) window.scrollTo(0, 0);
  }
}
