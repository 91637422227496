<header class="header">
  <div *ngIf="showTitle" class="header-text">
    <h1>CERTIFICADO DE TRADICIÓN Y LIBERTAD</h1>
  </div>
  <nav class="navigator">
    <button type="button" (click)="toggleMenu()" name="button">
      <img
        height="24"
        width="24"
        ngSrc="../../../assets/icons/Unicolor/menu-burger-white.svg"
        alt="Icono de un menu"
      >
    </button>
    <ul [ngClass]="{'hiddenMenu': !showMenu}">
      <li>
        <a routerLink="/"> Comprar certificado </a>
        <img
          height="24"
          width="24"
          ngSrc="../../../assets/icons/Unicolor/chevron-right-white.svg"
          alt="Icono de flecha"
        >
      </li>
      <li>
        <a routerLink="/redimir-codigo">Redimir código</a>
        <img
          height="24"
          width="24"
          ngSrc="../../../assets/icons/Unicolor/chevron-right-white.svg"
          alt="Icono de flecha"
        >
      </li>
      <li>
        <a routerLink="/recuperar-certificado">Recuperar certificado</a>
        <img
          height="24"
          width="24"
          ngSrc="../../../assets/icons/Unicolor/chevron-right-white.svg"
          alt="Icono de flecha"
        >
      </li>
      <li>
        <a
          rel="nofollow"
          class="nav-link"
          href="https://certificados.supernotariado.gov.co/certificado/external/validation/validate.snr"
          target="_blank"
        >
          Validar certificado
        </a>
      </li>
      <li>
        <a routerLink="/preguntas-frecuentes">Preguntas frecuentes</a>
        <img
          height="24"
          width="24"
          ngSrc="../../../assets/icons/Unicolor/chevron-right-white.svg"
          alt="Icono de flecha"
        >
      </li>
      <li>
        <a routerLink="/blog">Blog</a>
        <img
          height="24"
          width="24"
          ngSrc="../../../assets/icons/Unicolor/chevron-right-white.svg"
          alt="Icono de flecha"
        >
      </li>
    </ul>
  </nav>
</header>
