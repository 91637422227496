<footer class="general">
  <main class="general__content">
    <!-- <div>
      <p>
        Pagina privada, no pertenece a la Superintendencia de Notariado y
        Registro.
      </p>
    </div> -->
    <div class="text">
      <div>
        <p>Megapagos.co S.A.S</p>
        <p>Calle 7 sur # 42-70</p>
        <p>Medellin, Antioquia</p>
      </div>
      <div>
        <p class="bold">Soporte</p>
        <p>megapagos&#64;gmail.com</p>
        <p>Tel: 3146681005</p>
      </div>
    </div>
    <div class="content-center">
      <p (click)="goTop()">Inicio</p>
      <p>
        <a
          href="https://certificados.supernotariado.gov.co/certificado/external/validation/validate.snr"
          target="_blank"
        >
          Validar certificado
        </a>
      </p>
      <p><a routerLink="/recuperar-certificado">Recuperar certificado</a></p>
      <p><a routerLink="/preguntas-frecuentes">Preguntas frecuentes</a></p>
      <p><a routerLink="/blog">Blog</a></p>
    </div>
    <div>
      <p><a routerLink="/terminos-y-condiciones">Términos y condiciones</a></p>
      <p><a routerLink="/politicas-privacidad">Políticas de privacidad</a></p>
      <p><a routerLink="/preguntas-frecuentes">Preguntas frecuentes</a></p>
    </div>
  </main>
</footer>
